<template>
  <div>
    <notifications group="notification" position="bottom right" />

    <div class="row">
      <div class="col-12">
        <form class="form">
          <div class="card card-custom card-sticky">
            <div class="card-header" style="">
              <div class="card-title">
                <h3 class="card-label">
                  {{ $t("WORKERS.EDIT_WORKER") }}: {{ worker.firstName }}
                  {{ worker.lastName }}
                </h3>
              </div>
              <CardToolbar
                @submitEvent="onSubmit"
                back-url="/workers/list"
                buttonText="Edit Worker"
              ></CardToolbar>
            </div>
            <div class="card-body p-0">
              <div
                class="row justify-content-center py-8 px-8 py-lg-15 px-lg-10"
              >
                <div class="col-xl-12 col-xxl-10">
                  <div class="row justify-content-center">
                    <div class="col-xl-9">
                      <h5 class="text-dark font-weight-bold mb-10">
                        {{ $t("WORKERS.PERSONAL_DETAILS") }}
                      </h5>
                      <div class="form-group row">
                        <label class="col-xl-3 col-lg-3 col-form-label"
                          >{{ $t("GENERAL.NAME") }}:</label
                        >
                        <div class="col-lg-9 col-xl-9">
                          <input
                            id="firstName"
                            class="form-control form-control-lg"
                            v-model="worker.firstName"
                            name="firstName"
                            type="text"
                            :placeholder="$t('GENERAL.NAME')"
                          />
                        </div>
                      </div>

                      <div class="form-group row">
                        <label class="col-xl-3 col-lg-3 col-form-label"
                          >{{ $t("GENERAL.LAST_NAME") }}:</label
                        >
                        <div class="col-lg-9 col-xl-9">
                          <input
                            class="form-control form-control-lg"
                            v-model="worker.lastName"
                            name="lastName"
                            type="text"
                            :placeholder="$t('GENERAL.LAST_NAME')"
                          />
                        </div>
                      </div>

                      <div class="form-group row">
                        <label class="col-xl-3 col-lg-3 col-form-label"
                          >{{ $t("GENERAL.ADDRESS") }}:</label
                        >
                        <div class="col-lg-9 col-xl-9">
                          <input
                            class="form-control"
                            v-model="worker.address"
                            name="address"
                            type="text"
                            :placeholder="$t('GENERAL.ADDRESS')"
                          />
                        </div>
                      </div>

                      <div class="form-group row">
                        <label class="col-xl-3 col-lg-3 col-form-label"
                          >{{ $t("GENERAL.OIB") }}:</label
                        >
                        <div class="col-lg-9 col-xl-9">
                          <input
                            class="form-control"
                            v-model="worker.oib"
                            name="oib"
                            type="text"
                            :placeholder="$t('GENERAL.OIB')"
                          />
                        </div>
                      </div>

                      <div class="form-group row">
                        <label class="col-xl-3 col-lg-3 col-form-label"
                          >{{ $t("GENERAL.PASSPORT") }}:</label
                        >
                        <div class="col-lg-9 col-xl-9">
                          <input
                            class="form-control"
                            v-model="worker.passport"
                            name="passport"
                            type="text"
                            :placeholder="$t('GENERAL.PASSPORT')"
                          />
                        </div>
                      </div>

                      <div class="form-group row">
                        <label class="col-xl-3 col-lg-3 col-form-label"
                          >{{ $t("GENERAL.MOBILE") }}:</label
                        >
                        <div class="col-lg-9 col-xl-9">
                          <div class="input-group">
                            <div class="input-group-prepend">
                              <span class="input-group-text"
                                ><i class="la la-phone"></i
                              ></span>
                            </div>
                            <input
                              class="form-control"
                              v-model="worker.mob"
                              name="mobileNumber"
                              type="number"
                              :placeholder="$t('GENERAL.MOBILE')"
                            />
                          </div>
                        </div>
                      </div>

                      <div class="form-group row">
                        <label class="col-xl-3 col-lg-3 col-form-label"
                          >{{ $t("GENERAL.EMAIL") }}:</label
                        >
                        <div class="col-lg-9 col-xl-9">
                          <div class="input-group">
                            <div class="input-group-prepend">
                              <span class="input-group-text"
                                ><i class="la la-at"></i
                              ></span>
                            </div>
                            <input
                              class="form-control"
                              v-model="worker.email"
                              name="email"
                              type="email"
                              :placeholder="$t('GENERAL.EMAIL')"
                            />
                          </div>
                        </div>
                      </div>

                      <div class="form-group row">
                        <label class="col-xl-3 col-lg-3 col-form-label"
                          >{{ $t("GENERAL.FATHERS_NAME") }}:</label
                        >
                        <div class="col-lg-9 col-xl-9">
                          <input
                            class="form-control"
                            v-model="worker.fathersName"
                            name="email"
                            type="text"
                            :placeholder="$t('GENERAL.FATHERS_NAME')"
                          />
                        </div>
                      </div>

                      <div class="form-group row">
                        <label class="col-xl-3 col-lg-3 col-form-label"
                          >{{ $t("GENERAL.MOTHERS_NAME") }}:</label
                        >
                        <div class="col-lg-9 col-xl-9">
                          <input
                            class="form-control"
                            v-model="worker.mothersName"
                            name="email"
                            type="text"
                            :placeholder="$t('GENERAL.MOTHERS_NAME')"
                          />
                        </div>
                      </div>

                      <div class="separator separator-dashed my-10"></div>

                      <h5 class="text-dark font-weight-bold mb-10">
                        {{ $t("GENERAL.SALARY") }}
                      </h5>

                      <div class="form-group row">
                        <label class="col-xl-3 col-lg-3 col-form-label"
                          >{{ $t("GENERAL.SALARY") }}:</label
                        >
                        <div class="col-lg-9 col-xl-9">
                          <div class="input-group">
                            <input
                              class="form-control"
                              v-model.number="worker.wage"
                              name="salary"
                              type="text"
                              :placeholder="$t('GENERAL.SALARY')"
                            />
                            <div class="input-group-prepend">
                              <span class="input-group-text"
                                ><i class="la la-euro"></i
                              ></span>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="form-group row">
                        <label class="col-xl-3 col-lg-3 col-form-label"
                          >{{
                            $t("GENERAL.WORK_PERMIT_EXPIRATION_DATE")
                          }}:</label
                        >
                        <div class="col-lg-9 col-xl-9">
                          <vc-date-picker
                            v-model="worker.workPermitDate"
                            :masks="masks"
                          >
                            <template v-slot="{ inputValue, togglePopover }">
                              <div class="input-group" @click="togglePopover()">
                                <input
                                  class="form-control"
                                  :value="inputValue"
                                  name="workPermitDate"
                                  type="text"
                                />
                                <div class="input-group-prepend cursor-pointer">
                                  <span class="input-group-text"
                                    ><i class="la la-calendar"></i
                                  ></span>
                                </div>
                              </div>
                            </template>
                          </vc-date-picker>
                        </div>
                      </div>

                      <div class="form-group row">
                        <label class="col-xl-3 col-lg-3 col-form-label"
                          >{{ $t("GENERAL.MEDICAL_EXPIRATION_DATE") }}:</label
                        >
                        <div class="col-lg-9 col-xl-9">
                          <vc-date-picker
                            v-model="worker.physicalExamDate"
                            :masks="masks"
                          >
                            <template v-slot="{ inputValue, togglePopover }">
                              <div class="input-group" @click="togglePopover()">
                                <input
                                  class="form-control"
                                  :value="inputValue"
                                  name="workPermitDate"
                                  type="text"
                                />
                                <div class="input-group-prepend cursor-pointer">
                                  <span class="input-group-text"
                                    ><i class="la la-calendar"></i
                                  ></span>
                                </div>
                              </div>
                            </template>
                          </vc-date-picker>
                        </div>
                      </div>

                      <div class="form-group row">
                        <label class="col-xl-3 col-lg-3 col-form-label"
                          >{{ $t("GENERAL.WORK_SAFETY") }}:</label
                        >
                        <div class="col-lg-9 col-xl-9">
                          <CSwitch
                            @checkedEvent="toggleWorkProtection"
                            classes="switch-icon"
                            name="workProtection"
                            :is-checked="worker.workProtection"
                          />
                        </div>
                      </div>

                      <div class="form-group row">
                        <label class="col-xl-3 col-lg-3 col-form-label"
                          >{{ $t("GENERAL.FIRST_AID") }}:</label
                        >
                        <div class="col-lg-9 col-xl-9">
                          <CSwitch
                            @checkedEvent="toggleFirstAid"
                            classes="switch-icon"
                            name="firstAid"
                            :is-checked="worker.firstAid"
                          />
                        </div>
                      </div>

                      <div class="form-group row">
                        <label class="col-xl-3 col-lg-3 col-form-label"
                          >{{ $t("GENERAL.GEDA") }}:</label
                        >
                        <div class="col-lg-9 col-xl-9">
                          <CSwitch
                            @checkedEvent="toggleGeda"
                            classes="switch-icon"
                            name="geda"
                            :is-checked="worker.geda"
                          />
                        </div>
                      </div>

                      <div class="separator separator-dashed my-10"></div>

                      <div class="form-group row">
                        <label class="col-xl-3 col-lg-3 col-form-label"
                          >{{ $t("GENERAL.BORROWED_TOOLS") }}:</label
                        >
                        <div class="col-lg-9 col-xl-9">
                          <textarea
                            class="form-control"
                            v-model="worker.tools"
                            rows="4"
                            :placeholder="$t('GENERAL.BORROWED_TOOLS')"
                          ></textarea>
                        </div>
                      </div>

                      <div class="form-group row">
                        <label class="col-xl-3 col-lg-3 col-form-label"
                          >{{ $t("GENERAL.NOTE") }}:</label
                        >
                        <div class="col-lg-9 col-xl-9">
                          <textarea
                            class="form-control"
                            v-model="worker.note"
                            rows="4"
                            :placeholder="$t('GENERAL.NOTE')"
                          ></textarea>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import CSwitch from "@/view/content/components/switch/CSwitch.vue";
import CardToolbar from "@/view/content/components/card-toolbar/CardToolbar.vue";
import ApiService from "@/core/services/api.service";

export default {
  name: "EditWorker",
  components: { CardToolbar, CSwitch },
  data() {
    return {
      worker: {
        firstName: "",
        lastName: "",
        address: "",
        oib: "",
        email: "",
        mob: "",
        passport: "",
        fathersName: "",
        mothersName: "",
        workPermitDate: "",
        physicalExamDate: "",
        workProtection: true,
        firstAid: true,
        geda: true,
        overtimeHr: null,
        weekendHr: null,
        dailyWage: null,
        wage: null,
        nightHr: null,
        note: "",
        tools: "",
      },
      isWorkerLoaded: false,
      masks: {
        input: "DD.MM:YYYY.",
      },
      appPath: process.env.VUE_APP_ROUTE_API_URL,
    };
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: this.$t("MENU.ALL_WORKERS"), route: "/workers/list" },
      { title: this.$t("WORKERS.EDIT_WORKER") },
    ]);

    ApiService.get("workers/", this.$route.params.id).then(({ data }) => {
      this.worker.firstName = data.firstName;
      this.worker.lastName = data.lastName;
      this.worker.address = data.address;
      this.worker.oib = data.oib;
      this.worker.email = data.email;
      this.worker.mob = data.mob;
      this.worker.passport = data.passport;
      this.worker.fathersName = data.fathersName;
      this.worker.mothersName = data.mothersName;
      this.worker.workPermitDate = data.workPermitDate;
      this.worker.physicalExamDate = data.physicalExamDate;
      this.worker.workProtection = data.workProtection;
      this.worker.firstAid = data.firstAid;
      this.worker.geda = data.geda;
      this.worker.overtimeHr = data.overtimeHr;
      this.worker.weekendHr = data.weekendHr;
      this.worker.dailyWage = data.dailyWage;
      this.worker.wage = data.wage;
      this.worker.nightHr = data.nightHr;
      this.worker.note = data.note;
      this.worker.tools = data.tools;

      this.isWorkerLoaded = true;
    });
  },
  methods: {
    toggleWorkProtection() {
      this.worker.workProtection = !this.worker.workProtection;
    },
    toggleFirstAid() {
      this.worker.firstAid = !this.worker.firstAid;
    },
    toggleGeda() {
      this.worker.geda = !this.worker.geda;
    },
    onSubmit(type) {
      ApiService.update("workers", this.$route.params.id, this.worker).then(
        () => {
          this.$notify({
            group: "notification",
            type: "success",
            title: this.$t("WORKERS.UPDATE_SUCCESS"),
          });
          if (type === "1") {
            setTimeout(() => {
              this.$router.push({ name: "workers-list" });
            }, 1000);
          }
        }
      );
    },
  },
};
</script>
